import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client/core';

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_URL + "graphql" || "http://localhost:4001/graphql"
})

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    connectToDevTools: true,
});

export default apolloClient;